import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';

import { ConfirmModalComponent } from '@vandelft/modules/shared/components/ui/confirm-modal';
import { ModalService } from '@vandelft/modules/shared/services/modal.service';

import { PAYMENT_METHODS } from '@vandelft/modules/shared/constants';
import { PaymentFormModalComponent } from '@vandelft/modules/shared/components/work-orders/payment-form-modal';
import { Payment, WorkOrder } from '@vandelft/modules/shared/models';
import { DraftBaseFormComponent } from '../draft-base-form';
import { Select, Store } from '@ngxs/store';
import {
  CompleteWorkOrderDraft,
  PatchWorkOrderDraft,
  SaveWorkOrderDraft,
  WorkOrderDraftState,
} from '@vandelft/modules/shared/state/work-order-draft';
import { OpenReportsList } from '@vandelft/modules/shared/state/reports';
import { SetProgress } from '@vandelft/modules/shared/state/progress';
import { PaymentsHelper } from '@vandelft/shared/helpers';
import { filter } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import { environment } from '@vandelft/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-draft-completion-form',
  templateUrl: './draft-completion-form.component.html',
})
export class DraftCompletionFormComponent extends DraftBaseFormComponent implements OnInit {
  public isCompany = false;
  public deposit = 0;
  public totalPayments = 0;
  public totalProductsPriceInc = 0;
  public totalDeposit = 0;
  public toPay = 0;

  public paymentMethods = {};
  public apiUrl = environment.apiUrl;

  @Select(AuthState.token)
  public token$: Observable<string>;

  public form = new FormGroup({
    mechanicSignature: new FormControl(null, [Validators.required]),
    clientName: new FormControl(null, [Validators.required]),
    clientEmail: new FormControl(null, [Validators.email]),
    clientSignature: new FormControl(null, [Validators.required]),
    payments: new FormArray([], (c: FormArray) => {
      if (this.isCompany) {
        return null;
      }

      return c.length === 0 ? { minLengthError: true } : null;
    }),
  });

  public constructor(
    protected route: ActivatedRoute,
    protected store: Store,
    private modalService: ModalService,
  ) {
    super(route, store);

    for (const paymentMethod of PAYMENT_METHODS) {
      this.paymentMethods[paymentMethod.key] = paymentMethod.label;
    }
  }

  public get payments(): FormArray {
    return this.form.get('payments') as FormArray;
  }

  public ngOnInit(): void {
    this.store.dispatch(new SetProgress(4, 4));

    this.workOrder$.pipe(filter((a) => !!a)).subscribe((workOrder: WorkOrder) => {
      if (workOrder?.report?.client?.type === 'private') {
        this.form.get('clientEmail').setValidators(Validators.required);
        return;
      }
      this.form.get('clientEmail').clearValidators();
    });
  }

  public initForm(workOrder: WorkOrder): void {
    if (!workOrder || !this.form) {
      return;
    }

    this.isCompany = workOrder.report.client.type === 'company';
    this.form.patchValue({
      ...workOrder,
      clientName: workOrder.report.client.clientAddress.contact,
      clientEmail: workOrder.report.client.clientAddress.email,
    });

    if (!this.isCompany) {
      this.totalProductsPriceInc = workOrder.totalProductsPriceInc;
      this.totalDeposit = workOrder.totalDeposit;
      this.deposit = workOrder.effectiveDeposit;
      (this.form.get('payments') as FormArray).clear();
      for (const payment of workOrder.payments) {
        this.addPayment(payment);
      }

      this.updateDepositInfo();
    }

    if (workOrder.isEdit) {
      this.form.get('mechanicSignature').clearValidators();
      this.form.get('mechanicSignature').updateValueAndValidity();
      this.form.get('clientSignature').clearValidators();
      this.form.get('clientSignature').updateValueAndValidity();
    }
  }

  public async onSubmit(): Promise<any> {
    try {
      const formData = { ...this.form.value };
      const user = this.store.selectSnapshot(AuthState.user);
      const workOrderDraftBefore: WorkOrder = this.store.selectSnapshot(WorkOrderDraftState.workOrder);

      const data = {
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        report: {
          client: {
            clientAddress: {
              contact: formData.clientName,
              email: formData.clientEmail,
            },
          },
        },
        localClientSignature: formData.clientSignature,
        localMechanicSignature: formData.mechanicSignature,
        payments: formData.payments,
        state: 'completed',
        executed: new Date(),
      } as any;

      if (user.role === 'mechanic' && workOrderDraftBefore.isNew) {
        data.report.plannedEnd = moment().format('YYYY-MM-DD HH:mm:ss');
      }

      await firstValueFrom(this.store.dispatch(new PatchWorkOrderDraft(data)));
      const workOrderDraft: WorkOrder = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
      console.log(workOrderDraft);
      await firstValueFrom(this.store.dispatch(new SaveWorkOrderDraft(workOrderDraft)));
      await firstValueFrom(this.store.dispatch(new CompleteWorkOrderDraft(workOrderDraft)));
      return this.store.dispatch(new OpenReportsList());
    } catch (e) {
      console.error(e);
    }
  }

  public async openPaymentModal(index = null): Promise<void> {
    const formGroup = this.payments.controls[index];
    const modalRef = this.modalService.open(PaymentFormModalComponent);
    let payment = {
      amount: this.toPay,
    };

    if (formGroup) {
      payment = formGroup.value;
    }

    modalRef.componentInstance.payment = payment;
    const result = await modalRef.result;

    if (!result) {
      return;
    }

    if (formGroup) {
      formGroup.patchValue(result);
      this.updateDepositInfo();
      return;
    }

    this.addPayment({
      ...result,
      date: new Date(),
      id: uuid(),
    });

    this.updateDepositInfo();
  }

  public async deletePayment(e: Event, payment: AbstractControl): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (this.modalService.activeModal) {
      this.modalService.close();
    }

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      modalType: 'modal-floating-confirmation',
      barrier: false,
      alignment: {
        element: e.target,
      },
    });

    modalRef.componentInstance.message = `Weet u zeker dat u de betaling van € ${payment.value.amount} wilt verwijderen?`;
    const result = await modalRef.result;

    if (!result) {
      return;
    }

    const index = this.payments.controls.indexOf(payment);
    this.payments.removeAt(index);
    this.updateDepositInfo();
  }

  public addPayment(payment: Payment): void {
    const formGroup = new FormGroup({
      id: new FormControl(payment.id, [Validators.required]),
      amount: new FormControl(payment.amount, [Validators.required]),
      method: new FormControl(payment.method, [Validators.required]),
      date: new FormControl(payment.date, [Validators.required]),
      remarks: new FormControl(payment.remarks),
    });

    this.payments.push(formGroup);
    this.updateDepositInfo();
  }

  private updateDepositInfo(): void {
    const payments = [...this.form.value.payments];
    this.totalPayments = PaymentsHelper.getTotalPayed(payments);
    this.toPay = Math.round((this.totalDeposit - this.totalPayments) * 100) / 100;
  }
}
